var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.image
    ? _c(
        "div",
        { staticClass: "go-container" },
        [
          _c("transition", { attrs: { name: "go-transition" } }, [
            _c("div", { staticClass: "go-image", attrs: { "d-flex": "" } }, [
              _c("div", { staticClass: "go-image__w" }, [
                _c("img", { attrs: { src: _vm.image, alt: "" } }),
              ]),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
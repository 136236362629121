<template>
  <div class="go-container" v-if="image">
    <transition name="go-transition">
      <div d-flex class="go-image">
        <div class="go-image__w">
          <img :src="image" alt="" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "GameOverTeamImage",
  props: {
    teamID: String
  },
  computed: {
    image() {
      return this.$store.getters.chats?.[this.teamID]?.image
    }
  }
}
</script>

<style lang="scss">
.go-container {
  margin-bottom: -10px;
  height: 40vh;
}

.go-image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  &__w {
    border-radius: 16px;
    overflow: hidden;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 16px;
  }
}

.go-transition-enter,
.go-transition-leave-to {
  min-width: 0 !important;
  max-width: 0 !important;
  margin: 0 !important;

  .go-image {
    opacity: 0;
    transform: scale(0.3);
  }
}
</style>
